import React, { useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

interface SquareProps {
  value: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  // onClick: () => void;
}

function Square({ onClick, value }: SquareProps) {
  return (
    <div>
      <button className="square" onClick={onClick}>
        {value}
      </button>
    </div>
  );
}

function Board() {
  const rows = 6;
  const columns = 7;
  const [squares, setSquares] = useState<string[][]>(
    Array(rows)
      .fill(null)
      .map(() => new Array(columns).fill(null))
  );
  const [redIsNext, setRedIsNext] = useState(true);
  let status = redIsNext ? "It's RED's turn" : "It's YELLOW's turn";

  // updating winner state
  let winnerState = getGameResult(squares);

  const handleClick = (_: number, column: number) => {
    //return if move is invalid
    //return if there is a winner/tie
    if (getGameResult(squares) != "The game is in progress!") {
      return;
    }

    //return when the clicked column is full
    if (isColumnFull(squares, column)) {
      return;
    }

    //update the board
    const newSquares = [...squares];
    for (let row = rows - 1; row >= 0; row--) {
      if (squares[row][column] == null) {
        newSquares[row][column] = redIsNext ? "R" : "Y";
        break;
      }
    }

    setSquares(newSquares);

    //update the current player's turn
    setRedIsNext(!redIsNext);
  };

  // This function creates and returns closures.
  // function buildClickHandler(row: number, column: number) {
  //   return () => {
  //     handleClick(row, column);
  //   };
  // }

  const resetBoard = () => {
    setSquares(
      Array(rows)
        .fill(null)
        .map(() => new Array(columns).fill(null))
    );
    setRedIsNext(true);
  };

  return (
    <div>
      <div className="status">{status}</div>
      <div className="winner">{winnerState}</div>
      {squares.map((row, rowIndex) => {
        return (
          <div className="board-row">
            {row.map((square, columnIndex) => {
              return (
                <Square
                  value={square}
                  // onClick={buildClickHandler(rowIndex, columnIndex)}
                  onClick={() => handleClick(rowIndex, columnIndex)}
                />
              );
            })}
          </div>
        );
      })}
      <button onClick={resetBoard}>reset</button>
    </div>
  );
}

function isColumnFull(squares: string[][], column: number) {
  return squares[0][column] != null;
}

type GameResult =
  | "Red is the winner!"
  | "Yellow is the winner!"
  | "The game is in progress!"
  | "It is a tie!";

function getGameResult(squares: string[][]): GameResult {
  const maximumRow = 5;
  const maximumColumn = 6;
  // vertical check

  for (let column = 0; column <= maximumColumn; column++) {
    for (let row = 0; row <= maximumRow - 3; row++) {
      if (
        squares[row][column] == "R" &&
        squares[row + 1][column] == "R" &&
        squares[row + 2][column] == "R" &&
        squares[row + 3][column] == "R"
      ) {
        return "Red is the winner!";
      }
      if (
        squares[row][column] == "Y" &&
        squares[row + 1][column] == "Y" &&
        squares[row + 2][column] == "Y" &&
        squares[row + 3][column] == "Y"
      ) {
        return "Yellow is the winner!";
      }
    }
  }

  // horizontal check

  for (let row = 0; row <= maximumRow; row++) {
    for (let column = 0; column <= maximumColumn - 3; column++) {
      if (
        squares[row][column] == "R" &&
        squares[row][column + 1] == "R" &&
        squares[row][column + 2] == "R" &&
        squares[row][column + 3] == "R"
      ) {
        return "Red is the winner!";
      }
      if (
        squares[row][column] == "Y" &&
        squares[row][column + 1] == "Y" &&
        squares[row][column + 2] == "Y" &&
        squares[row][column + 3] == "Y"
      ) {
        return "Yellow is the winner!";
      }
    }
  }

  // down right check

  for (let row = 0; row <= maximumRow - 3; row++) {
    for (let column = 0; column <= maximumColumn - 3; column++) {
      if (
        squares[row][column] == "R" &&
        squares[row + 1][column + 1] == "R" &&
        squares[row + 2][column + 2] == "R" &&
        squares[row + 3][column + 3] == "R"
      ) {
        return "Red is the winner!";
      }
      if (
        squares[row][column] == "Y" &&
        squares[row + 1][column + 1] == "Y" &&
        squares[row + 2][column + 2] == "Y" &&
        squares[row + 3][column + 3] == "Y"
      ) {
        return "Yellow is the winner!";
      }
    }
  }

  // up right check
  for (let row = 3; row <= maximumRow; row++) {
    for (let column = 0; column <= maximumColumn - 3; column++) {
      if (
        squares[row][column] == "R" &&
        squares[row - 1][column + 1] == "R" &&
        squares[row - 2][column + 2] == "R" &&
        squares[row - 3][column + 3] == "R"
      ) {
        return "Red is the winner!";
      }
      if (
        squares[row][column] == "Y" &&
        squares[row - 1][column + 1] == "Y" &&
        squares[row - 2][column + 2] == "Y" &&
        squares[row - 3][column + 3] == "Y"
      ) {
        return "Yellow is the winner!";
      }
    }
  }
  for (let column = 0; column <= maximumColumn; column++) {
    if (squares[0][column] == null) {
      return "The game is in progress!";
    }
  }
  return "It is a tie!";
}

const Page = () => (
  <Layout>
    <SEO title="Connect4" />
    <h1>Connect 4</h1>
    <Board />
  </Layout>
);

export default Page;
